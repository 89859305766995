@import "normalize";

*, *:before, *:after {
  box-sizing: border-box;
}
section {
  position: relative;
  color: #fff;
  &:after {
    content: '';
    display: block;
    padding-bottom: 62%;
  }
}
h2 {
  font-family: adelle, sans-serif;
  font-weight: bold;
  font-size: 48px;
  text-align: center;
  color: #fff;
}
h3 {
  font-family: adelle, sans-serif;
  font-weight: bold;
  margin-bottom: 0;
  font-size: 22px;
}
a {
  text-decoration: none;
}
.content {
  width: 100%;
  text-align: center;
}
.nameHolder {
  display: block;
  font-family: adelle,sans-serif;
  font-size: 36px;
  line-height: 48px;
}
.verticalBox {
  position: absolute;
  top:50%;
  left:0;
  right:0;
  margin-top: -30px;
  text-align: center;
  font-size: 13px;
}
.button {
  font-family: adelle, sans-serif;
  padding: 15px 65px 15px 25px;
  font-size: 15px;
  text-transform: uppercase;
  border: 1px solid #fff;
  background: url(../img/gallery-ico.svg) no-repeat right 20px center rgba(0, 0, 0, 0.5);
  position:absolute;
  left:50%;
  top:50%;
  margin-left: -100px;
  margin-top: -10px;
  cursor:pointer;
  z-index: 6;
}
.aboutUsBox {
  position: relative;
  float: left;
  width: 25%;
}
.aboutUsTxt {
  position: absolute;
  right: 40px;
  bottom: 0;
  left: 40px;
  height: 40%;
  text-align: center;
  span,
  p {
    font-family: industry, sans-serif;
    display: inline-block;
    margin-bottom: 0;
    font-size: 15px;
    line-height: 24px;
  }
}
.toTop {
  display: block;
  width: 54px;
  height: 54px;
  margin: 0 auto 10px;
  cursor: pointer;
  background:url(../img/backToTop.svg);
}
.download {
  display: block;
  width: 25px;
  height: 25px;
  margin: 10px auto;
  background:url(../img/backToTop.svg);
  background-size: cover;
  -webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}
.section-main {
  font-family: adelle, sans-serif;
  background: url(../img/bgS1.jpg) center top;
  background-size: cover;
  max-height: 100vh;
  .content {
    position: absolute;
    bottom: 10%;
  }
  .logo {
    width: 20%;
    max-width: 300px;
  }
  .boxMain {
    width: 20%;
    padding: 0;
  }
  .boxLR {
    width: 40%;
    padding: 0 10%;
  }
  .nhL {
    left:18%;
  }
  .nhR {
    right:18%;
  }
  .nameHolder {
    position:absolute;
    bottom:20%;
    width:15%;
  }
}
.section-who {
  position: relative;
  font-family: industry, sans-serif;
  &:after {
    padding-bottom: 37.25%;
  }
  background: url(../img/bgS2.jpg) center top;
  background-size: cover;
  .content {
    display: table;
    padding-top: 20%;
    padding-bottom: 5%;
  }
  .boxMain {
    & > p {
      margin: 10px 0 0 0;
    }
    height:auto;
    display:block;
    width: 50%;
    margin:0 auto;
    padding: 10px 5%;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    position:absolute;
    left:0;
    right:0;
    bottom:15%;
    h2 {
      font-family: adelle, sans-serif;
      margin:0 0 25px;
    }
    p {
      font-size: 15px;
      line-height: 24px;
    }
  }
  .boxLR {
    position: absolute;
    bottom:15%;
    width: 25%;
    padding: 0 3%;
  }
  .nhL {
    left:0;
  }
  .nhR {
    right:0;
  }
  .nameHolder {
    margin-bottom: 10px;
    padding: 0 5% 15px;
    border-bottom: 1px solid #fff;
  }
}
.section-what {
  background-size: cover;
  &:after {
    padding-bottom:52%;
  }
  h2 {
    position:relative;
    top: 55px;
    z-index: 7;
    margin:0;
  }
}
.galleryPreview {
  position: absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index: 2;
  background: #000;
  img {
    position: absolute;
    width: 100%;
    height:100%;
    display:none;
  }
  .active {
    display:block;
  }
}
.section-showreel, .section-channel {
  background: #000;
  padding-top: 45px;
  &:after {
    padding-bottom:50px;
  }
}
.iframeHolder {
  max-width:1400px;
  margin: 0 auto;
  padding:0 50px 50px;
}
.aspectRatio {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56%;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
.section-about {
  background: #000;
  h2 {
    padding-top: 15px;
    position: absolute;
    width: 100%;
    z-index: 2;
  }
  img {
    float:left;
    width: 100%;
  }
}
.section-channel {
  color:#000;
}
.section-channel-youtube {
  background: url('../img/youtube.jpg') no-repeat center center #000;
  a {
    color: #fff;
  }
  .button {
    background: url(../img/play-ico.svg) no-repeat right 20px center rgba(0,0,0,.5);
    margin-left: -120px;
  }
}
.section-last {
  background: url(../img/bgS7.jpg) no-repeat top center;
  height: 100vh;
  background-size:cover;
  padding-top: 30px;
  & > span {
    text-transform: uppercase;
  }
  span {
    display: block;
    text-align: center;
    font-family: industry, sans-serif;
    &:last-of-type {
      margin-top: 3px;
    }
  }
  img {
    width: 100px;
    margin-bottom: 15px;
  }
  &:after {
    padding-bottom:0%;
  }
  .logo {
    background: url(../img/logo.png) bottom center no-repeat;
    background-size:contain;
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
}

@media screen and (min-width: 1600px) {
  .section-last {
    background-size: 100%;
  }
}
@media screen and (min-width: 1400px) {
  .aboutUsTxt {
    height:30%;
    bottom:100px;
  }
}
@media screen and (max-width: 1250px) {
  .nameHolder {
    font-size: 28px;
    line-height: 38px;
  }
  .aboutUsTxt {
    left:20px;
    right:20px;
  }
  .aboutUsTxt p {
    font-size:13px;
    line-height: 18px;
  }
}
@media screen and (max-width: 1100px) {
  .section-about {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 1000px) {
  h2 {
    font-size: 36px;
  }
  .nameHolder {
    font-size: 22px;
    line-height: 33px;
  }
  .aboutUsTxt {
    left:20px;
    right:20px;
    height: 50%;
  }
}
@media screen and (max-width: 850px) {
  .aboutUsTxt {
    left:10px;
    right:10px;
    height:60%;
  }
  .section-who {
    &:after {
      padding-bottom: 60%;
    }
    .boxMain {
      clear:both;
      float:left;
      width:100%;
      margin-bottom: 35%;
      border:none;
    }
    .boxLR {
      position:absolute;
      bottom:50px;
      width:50%;
      &:nth-child(1) {
        left:0;
      }
      &:nth-child(3) {
        right:0;
      }
    }
    .nameHolder {
      margin-left:15%;
      margin-right:15%;
      padding-left:15%;
      padding-right:15%;
    }
  }
}
@media screen and (max-width: 770px) {
  h2 {
    font-size: 30px;
  }
  .section-about:after {
    padding-bottom: percentage(1000 / 1600)*4;
  }
  .aboutUsBox:nth-of-type(3) {
    clear:both;
  }
  .aboutUsBox {
    width:50%;
    height: 100%;
    top: 70%;
    font-size: 15px;
    p {
      font-size: 15px;
      line-height: 20px;
    }
  }
  .aboutUsTxt {
    left:30px;
    right:30px;
    height:30%;
  }
  .nameHolder {
    font-size: 16px;
    line-height: 25px;
  }
  .section-main {
    .nameHolder {
      width: 21%;
    }
    .nhR {
      right: 13%;
    }
    .nhL {
      left: 13%;
    }
  }
  .section-who {
    min-height:700px;
    .boxMain {
      margin-bottom:0;
      top:10%;
    }
  }
  .section-last {
    background-size: auto;
    img {
      width:60px;
    }
    &:after {
      padding-bottom: 90%;
    }
  }
}
@media screen and (max-width: 600px) {
  .aboutUsTxt {
    left:20px;
    right:20px;
    height:45%;
  }
  .section-what:after {
    padding-bottom: 50%;
  }
}
@media screen and (max-width: 450px) {
  h2 {
    font-size: 28px;
  }
  .aboutUsBox {
    clear:both;
    width:100%;
    height: 100%;
  }
  .aboutUsTxt {
    height:30%;
  }
  .nameHolder {
    font-size: 15px;
    line-height: 22px;
  }
  .button {
    background-position: right 13px center;
    padding: 10px 55px 10px 15px;
  }
  .iframeHolder {
    padding:0;
  }
  .section-main {
    .nameHolder {
      bottom:0;
      width:24%;
    }
    .nhL {
      left:12%;
    }
    .nhR {
      right:12%;
    }
  }
  .section-about {
    &:after {
      padding-bottom: percentage(1000 / 1600)*4*4;
    }
    h2 {
      padding-top:15%;
    }
  }
  .section-who {
    .boxMain {
      margin-bottom: 60%;
    }
    .content {
      padding-top: 10%;
    }
    .nameHolder {
      padding: 0 20% 15px;
      margin-left:0;
      margin-right:0;
    }
  }
  .section-what {
    h2 {
      top:25px;
    }
  }
}
@media screen and (max-width: 361px) {
  .section-who {
    .boxMain {
      top:5%;
    }
  }
}
